<template>
    <div id="content" class="supervise-checklist">
        <ViewConfigForm
            v-if="displayViewConfigForm"
            @view-created-or-edited="handleDisplayView"
            @on-view-deleted="setDefaultViewAsSelectedView"
            @on-cancel-button="closeViewConfigForm"
            :isEdit="configViewIsEdit"
            :selectedView="selectedView"
        />

        <div class="supervise-top-bar-actions">
            <!-- Show/Hide Filters Button, Views Dropdown Menu and Ediv View Button -->

            <div class="sidebar-filters-top-buttons">
                <!-- botón de ocultar filtros sin label -->
                <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
            </div>

            <!-- Table Top Buttons -->
            <div class="supervise-table-actions-buttons">
                <Button
                    bType="file"
                    :bLabel="$tc('template.download.popup.exportReports')"
                    :bCallback="exportTimers"
                    v-if="Object.values(items).length > 0"
                ></Button>
            </div>
        </div>

        <div class="supervise-container">
            <!-- SIDEBAR FILTERS -->
            <div
                class="sidebar-filters"
                :class="{
                    'empty-results': items.length == 0,
                    'hidden-filters-sidebar': filtersSidebarIsHidden
                }"
            >
                <SuperviseOperationalTimersFiltersBox :key="filterKey" ref="filterOpertationalTimers" :applyFilter="applyFilter" />
            </div>
            <!-- TABLE timers-->
            <TableOperationalTimers :timers="items" @singTimer="singTimer"></TableOperationalTimers>
        </div>
    </div>
</template>

<script>
import SuperviseOperationalTimersFiltersBox from '@/components/domain/operationalTimers/supervise/SuperviseOperationalTimersFiltersBox'
import TableOperationalTimers from '../components/ui/TableOperationalTimers.vue'
import EmptyTable from '@/components/ui/EmptyTable'
import DropdownMenuWithSearchBar from '@/components/domain/filtersViews/DropdownMenuWithSearchBar.vue'
import ViewConfigForm from '@/components/domain/filtersViews/ViewConfigForm.vue'

let previousRoute

export default {
    components: {
        SuperviseOperationalTimersFiltersBox,
        TableOperationalTimers,
        EmptyTable,
        DropdownMenuWithSearchBar,
        ViewConfigForm
    },
    name: 'SuperviseOperationalTimers',
    data() {
        return {
            showReset: false,
            applyFilter: false,
            noChanges: false,
            // VIEW VARIABLES
            reportView: false,
            aReportsID: [],

            // DATATABLES
            values: false,
            singleSelect: false,
            selected: [],
            options: {},
            filterKey: 1,
            sortBy: false,
            sortType: false,
            changePagination: false,
            changedItemPerPage: false,
            filtersSidebarIsHidden: false,

            // Filters VIEWS
            selectedView: null,
            displayViewConfigForm: false,
            configViewIsEdit: false,

            pdfGenerationTimeLimit: 5000, // 5 seconds threshold
            pdfGenerationStart: null
        }
    },
    computed: {
        items() {
            return this.$store.getters['operationalTimers/getSupervisingOperationalTimers']
        },
        currentFilters() {
            return this.$store.getters['operationalTimers/getSupervisionCurrentFilters']
        }
    },
    methods: {
        showExportByEmailPopup() {
            const self = this

            const user = self.$store.getters['loginUser/getLocalUser']
            const email = user.email

            const messageWithEmail = self.$t('supervise.reports.export.popup_email.message', { email: `<b>${email}</b>` })
            const messageWithoutEmail = self.$t('supervise.reports.export.popup_email.message2')

            self.$popup.messageWithButton({
                title: self.$t('supervise.reports.export.popup_email.title'),
                message: email ? messageWithEmail : messageWithoutEmail,
                callCancel: () => {
                    self.$popup.close()
                }
            })
        },
        singTimer(itemInfo) {
            this.$overlay.loading()
            if (itemInfo) {
                const currentFilters = this.currentFilters
                const employee = this.$store.getters['login/getLocalEmployee']
                const location = currentFilters.location.id
                if (employee) {
                    itemInfo.employee_id = employee.employee_id
                    itemInfo.location_id = location

                    this.$store.dispatch('operationalTimers/singTimer', itemInfo).then(() => {
                        this.$overlay.hide()
                    })
                }
            }
        },
        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('settingsSuperviseIssueSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('settingsSuperviseIssueSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        },
        exportTimers() {
            const self = this
            this.$overlay.show()
            this.pdfGenerationStart = Date.now()

            const filters = {
                location: this.currentFilters.location.id,
                start_date: this.currentFilters.start_date ? this.currentFilters.start_date : null,
                end_date: this.currentFilters.end_date ? this.currentFilters.end_date : null,
                type_date: this.currentFilters.type_date
            }
            if (this.currentFilters.type_date === 'custom') {
                filters.start_date = this.currentFilters.start_date
                filters.end_date = this.currentFilters.end_date
            } else {
                filters.start_date = this.currentFilters.type_date
                filters.end_date = null
            }
            if (this.currentFilters.templates && this.currentFilters.templates.length > 0) {
                filters.templates = this.currentFilters.templates
            }

            this.$store
                .dispatch('operationalTimers/exportPdf', filters)
                .then((response) => {
                    // if error code is -2, show the modal that says that pdfs will be sent by email:
                    if (!response.status && response.error.code == -2) {
                        // close loader
                        self.$overlay.hide()
                        self.showExportByEmailPopup()
                        return
                    } else {
                        let filename = response.file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.target = '_blank'
                        link.href = response.file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        this.$overlay.hide()
                    }
                })
                .catch((error) => {
                    console.error(error)
                    this.$overlay.hide()
                })
        }
    },

    watch: {},

    created() {},

    mounted() {},

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.previousRoute = from.fullPath

            previousRoute = vm.previousRoute
        })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.supervise-checklist {
    .supervise-container {
        display: flex;
        align-items: stretch;

        gap: 0.5em;

        .data-table {
            height: 100%;

            .v-data-table,
            .v-data-table table {
                .done,
                .progress,
                .warning,
                .alert {
                    font-family: $text !important;
                }
                .done {
                    color: $color-success-500;
                }
                .progress {
                    color: $color-success-500;
                }
                .warning {
                    color: $color-warning-500;
                }
                .alert {
                    color: $color-error-500;
                }

                .complete_date,
                .score {
                    text-align: center;
                }
                .score .alert {
                    color: $color-error-500;
                    font-family: $text-bold;
                }

                .location_name span {
                    font-family: $text-medium !important;
                }

                .title-template {
                    display: block;
                    font-family: $text !important;
                    @include font-size(sm);
                }

                .hide {
                    display: none;
                }

                .na {
                    @include align-items();
                    @include border-radius(4px);
                    @include text-ellipsis();
                    @include background($image: img('error_main_s40.svg'), $size: 15px, $position: left 0px center);
                    @include font-size('sm');
                    padding: 3px 9px 3px 22px;
                    text-align: center;
                    // background-color: $color-primary-100;
                    // padding: 3px 9px 3px 30px;
                    font-family: $text-bold;
                    color: $color-primary-500;
                    width: min-content;
                    margin: 0 auto;
                }

                .end_date {
                    font-family: $text-bold;
                    color: $color-error-500;
                }
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
