<template>
    <div class="filter-box">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>

        <SuperviseOperationalTimersFilterInput
            :currentFilters="currentFilters"
            :activeFilters="activeFilters"
            @inputChange="(params) => updateCurrentFilters(params)"
        />

        <FilterActions
            :showClearFiltersButton="changesDetected"
            :clearFiltersCallback="resetFilters"
            :filterCallback="applyFilters"
            :changesDetected="changesDetected"
        />
    </div>
</template>

<script>
import Tags from '@/components/ui/Tags'
import FilterActions from '../../../ui/FilterActions.vue'
import SuperviseOperationalTimersFilterInput from './SuperviseOperationalTimersFilterInput.vue'

const defaultFilters = {
    location: {},
    template: [],
    type_date: 'week',
    start_date: 'week',
    end_date: '',
}

export default {
    name: 'SuperviseOperationalTimersFiltersBox',
    components: {
        Tags,
        FilterActions,
        SuperviseOperationalTimersFilterInput
    },
    props: {
        applyFilter: { type: Boolean, default: false },
    },
    data() {
        return {
            // Filters states
            currentFilters: structuredClone(defaultFilters),
            emptyFilters: structuredClone(defaultFilters),
            activeFilters: structuredClone(defaultFilters),

            // Filter variables
            changesDetected: false
        }
    },
    computed: {
    },

    methods: {
        async load() {
            this.$overlay.loading()
            await this.$store.dispatch('operationalTimers/loadSupervisionOpTimersFilters')
            this.currentFilters = structuredClone(this.$store.getters['operationalTimers/getSupervisionCurrentFilters'])
            this.activeFilters = structuredClone(this.$store.getters['operationalTimers/getSupervisionCurrentFilters'])

            const filters = {
                location: this.currentFilters.location ? this.currentFilters.location.id: null,
                type_date: this.currentFilters.type_date ? this.currentFilters.type_date : 'week',
                start_date: this.currentFilters.type_date !== 'custom' ? this.currentFilters.type_date : (this.currentFilters.start_date ? this.currentFilters.start_date : null),
                end_date: this.currentFilters.end_date ? this.currentFilters.end_date : null,
            }
            if(this.currentFilters.template && this.currentFilters.template.length > 0) {
                filters.template = this.currentFilters.template.map((tpl) => tpl.id)
            }

            await this.$store.dispatch('operationalTimers/loadOperationalTimersTemplate')
            await this.$store.dispatch('operationalTimers/loadSuperviseOperationalTimers', filters)
            await this.$store.dispatch('employee/loadList',{visible:0})
            this.$overlay.hide()
            
        },

        updateCurrentFilters(params) {
            const { inputName, value } = params
            const clonedFilters = structuredClone(this.currentFilters)
            if(inputName === 'location') {
                clonedFilters.location
            }

            if(inputName === 'template') {
                clonedFilters.template = value
            }

            if(inputName === 'type_date') {
                clonedFilters.type_date = value
            }

            if(inputName === 'start_date') {
                clonedFilters.start_date = value
            }

            if(inputName === 'end_date') {
                clonedFilters.end_date = value
            }

            this.currentFilters = clonedFilters
            this.changesDetected = !_.isEqual(this.currentFilters, this.activeFilters)
        },

        applyFilters() {
            this.$overlay.loading()
            const filters = {
                location: this.currentFilters.location ? this.currentFilters.location.id: null,
                type_date: this.currentFilters.type_date ? this.currentFilters.type_date : 'week',
                start_date: this.currentFilters.type_date !== 'custom' ? this.currentFilters.type_date : (this.currentFilters.start_date ? this.currentFilters.start_date : null),
                end_date: this.currentFilters.end_date ? this.currentFilters.end_date : null,
            }
            if(this.currentFilters.template && this.currentFilters.template.length > 0) {
                filters.template = this.currentFilters.template.map((tpl) => tpl.id)
            }

            this.$store.commit('operationalTimers/setSupervisionCurrentFilters', this.currentFilters)

            this.$store.dispatch('operationalTimers/loadSuperviseOperationalTimers', filters).then(() => {
                this.$overlay.hide()
            })
            this.activeFilters = structuredClone(this.currentFilters)
            this.changesDetected = false
        },

        resetFilters() {
            this.currentFilters = structuredClone(this.$store.getters['operationalTimers/getSupervisionEmptyFilters'])
            this.activeFilters = structuredClone(this.$store.getters['operationalTimers/getSupervisionEmptyFilters'])
            this.applyFilters()
        },
    },

    watch: {
    },

    mounted() {
        
        this.load()
    },

    created() {
        
    }
}
</script>

<style lang="scss" scoped>
// Busca en assets :^)
</style>
