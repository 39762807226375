var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-items"},[_vm._t("default"),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.filters.search_by')))]),(Object.values(_vm.optionsLocations))?_c('v-autocomplete',{staticClass:"select",class:{
                filtered: _vm.currentFilters.location && typeof _vm.currentFilters.location !== 'undefined' && Object.values(_vm.currentFilters.location).length > 0,
                changed:
                    JSON.stringify(_vm.currentFilters.location) != JSON.stringify(_vm.activeFilters.location) && Object.values(_vm.currentFilters.location).length > 0
            },attrs:{"hide-details":"","items":Object.values(_vm.optionsLocations),"item-text":"name","item-value":"id","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}],null,false,257687894),model:{value:(_vm.currentFilters.location),callback:function ($$v) {_vm.$set(_vm.currentFilters, "location", $$v)},expression:"currentFilters.location"}}):_vm._e(),_c('Tags',{key:_vm.currentFilters.location.id,attrs:{"tags":[_vm.currentFilters.location],"backImage":'location_inactive_s30.svg',"nameTag":'name',"value":"id"}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.timers.timersTemplate')))]),_c('v-autocomplete',{staticClass:"select",class:{
                filtered: _vm.currentFilters.template ? _vm.currentFilters.template && Object.values(_vm.currentFilters.template).length > 0 : '',
                changed:
                    JSON.stringify(_vm.currentFilters.template) != JSON.stringify(_vm.activeFilters.template) &&
                    _vm.currentFilters.template &&
                    Object.values(_vm.currentFilters.template).length > 0
            },attrs:{"value":_vm.currentFilters.template,"items":Object.values(_vm.optionsTemplates),"hide-details":"","item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"filter":_vm.filterAutocomplete,"return-object":""},on:{"input":function (templateInputValue) {
                    _vm.$emit('inputChange', { inputName: 'template', value: templateInputValue })
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{staticClass:"options",attrs:{"no-gutters":""},domProps:{"innerHTML":_vm._s(item.name)}})],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}])}),_c('Tags',{attrs:{"tags":_vm.currentFilters.template,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter date"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.date')))]),_c('v-select',{staticClass:"select",class:{
                filtered: _vm.currentFilters.type_date,
                changed: JSON.stringify(_vm.currentFilters.type_date) != JSON.stringify(_vm.activeFilters.type_date)
            },attrs:{"value":_vm.currentFilters.type_date,"items":_vm.optionsDate,"hide-details":"","item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results')},on:{"input":function (value) {
                    _vm.$emit('inputChange', { inputName: 'type_date', value: value })
                }}}),(_vm.currentFilters.type_date == 'custom')?_c('div',{staticClass:"custom"},[_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.from'))+":")]),_c('input',{staticClass:"date-input start",attrs:{"max":this.currentFilters.end_date,"type":"date"},domProps:{"value":_vm.currentFilters.start_date},on:{"input":function (event) {
                        _vm.$emit('inputChange', { inputName: 'start_date', value: event.target.value })
                    }}}),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.to'))+":")]),_c('input',{staticClass:"date-input end",attrs:{"min":_vm.currentFilters.start_date,"type":"date"},domProps:{"value":_vm.currentFilters.end_date},on:{"input":function (event) {
                        _vm.$emit('inputChange', { inputName: 'end_date', value: event.target.value })
                    }}})]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }