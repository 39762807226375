<template>
    <div @click="openReport(item.item)" class="created-by" :class="{ tooltip: tooltip }">
        <div class="table-img-container">
            <div :style="{ backgroundColor: colored(this.name) }" class="employee-initials">{{ this.name.charAt(0) + this.surname.charAt(0) }}</div>
        </div>
        <div class="created-name" :class="{ 'tooltip-text': tooltip }">
            <span class="employee-name">{{ this.name + ' ' + this.surname }}</span>
        </div>
    </div>
    <!-- <div class="no-employee" v-else>-</div> -->
</template>

<script>
export default {
    name: 'TableCreatedBy',
    props: {
        item: { type: Object },
        name: { type: String, default: '-' },
        surname: { type: String, default: '' },
        tooltip: { type: Boolean, default: false }
    },
    methods: {
        openReport(item) {
            this.$emit('goReport', item)
        },
        colored(name) {
            const n = name.charAt(0)
            const color = extraColorsFn(n)
            return color
        }
    }
}
</script>

<style lang="scss">
div.created-by {
    display: flex;
    flex-direction: row;
    &.tooltip {
        position: relative;
        z-index: 99;
        &:hover {
            .tooltip-text {
                display: grid;
            }
        }
    }
    div.table-img-container {
        width: fit-content;
        position: relative;
        z-index: 10;
        div.employee-initials {
            display: grid;
            place-content: center;
            font-size: 13px;
            vertical-align: middle;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            color: white;
            z-index: 10;
        }
    }

    div.created-name {
        display: grid;
        place-content: middle;
        .employee-name {
            @include text-ellipsis();
            vertical-align: middle;
            padding-left: 6px;
            color: $color-black;
            z-index: 11;
        }

        &.tooltip-text {
            position: absolute;
            left: 50%;
            padding: 2px 4px;
            transform: translate(-50%, 4%);
            background-color: $color-secondary-500;
            border-radius: 4px;
            display: none;
            transition: all 0.3s ease-in-out;

            &::before {
                content: '';
                position: absolute;
                top: -8px;
                left: 50%;
                transform: translate(-50%, 0);
                border: 4px solid transparent;
                border-bottom-color: $color-secondary-500;
                transition: all 0.3s ease-in-out;
            }

            .employee-name {
                color: white;
                @include font-size(xs);
                padding: 0;
                z-index: 11;
            }
        }
    }

    /* Cuando haga hover sobre la imagen que aparezca el tooltip */
    div.table-img-container:hover + div.created-name {
        display: inline;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        z-index: 11;
    }
}
</style>
