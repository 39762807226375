<template>
    <div class="filter-items">
        <!-- (SLOT is necesary for ViewConfigForm) -->
        <slot></slot>
        <!-- LOCATIONS -->
        <div class="filter">
            <label>{{ $t('user.filters.search_by') }}</label>
            <v-autocomplete
                :class="{
                    filtered: currentFilters.location && typeof currentFilters.location !== 'undefined' && Object.values(currentFilters.location).length > 0,
                    changed:
                        JSON.stringify(currentFilters.location) != JSON.stringify(activeFilters.location) && Object.values(currentFilters.location).length > 0
                }"
                hide-details
                class="select"
                v-model="currentFilters.location"
                v-if="Object.values(optionsLocations)"
                :items="Object.values(optionsLocations)"
                item-text="name"
                item-value="id"
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="$t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags
                :key="currentFilters.location.id"
                :tags="[currentFilters.location]"
                :backImage="'location_inactive_s30.svg'"
                :nameTag="'name'"
                value="id"
            ></Tags>
        </div>

        <!-- TEMPLATES -->
        <div class="filter">
            <label>{{ $t('supervise.timers.timersTemplate') }}</label>
            <v-autocomplete
                :value="currentFilters.template"
                class="select"
                @input="
                    (templateInputValue) => {
                        $emit('inputChange', { inputName: 'template', value: templateInputValue })
                    }
                "
                :class="{
                    filtered: currentFilters.template ? currentFilters.template && Object.values(currentFilters.template).length > 0 : '',
                    changed:
                        JSON.stringify(currentFilters.template) != JSON.stringify(activeFilters.template) &&
                        currentFilters.template &&
                        Object.values(currentFilters.template).length > 0
                }"
                :items="Object.values(optionsTemplates)"
                hide-details
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="$t('assets.filter.filter_by')"
                :filter="filterAutocomplete"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>

                <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters class="options" v-html="item.name"></v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-autocomplete>

            <Tags :tags="currentFilters.template" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <!-- TYPE DATE -->
        <div class="filter date">
            <label>{{ $t('supervise.checklists.table_headers.date') }}</label>
            <v-select
                :value="currentFilters.type_date"
                :class="{
                    filtered: currentFilters.type_date,
                    changed: JSON.stringify(currentFilters.type_date) != JSON.stringify(activeFilters.type_date)
                }"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'type_date', value: value })
                    }
                "
                :items="optionsDate"
                hide-details
                class="select"
                item-text="name"
                item-value="value"
                dense
                :no-data-text="$t('supervise.filter.no_results')"
            ></v-select>

            <!-- CUSTOM DATE -->
            <div class="custom" v-if="currentFilters.type_date == 'custom'">
                <!-- START DATE -->
                <div class="description">{{ $t('assets.filter.from') }}:</div>

                <input
                    :value="currentFilters.start_date"
                    @input="
                        (event) => {
                            $emit('inputChange', { inputName: 'start_date', value: event.target.value })
                        }
                    "
                    :max="this.currentFilters.end_date"
                    type="date"
                    class="date-input start"
                />

                <div class="separator"></div>

                <!-- END DATE -->
                <div class="description">{{ $t('assets.filter.to') }}:</div>

                <input
                    :value="currentFilters.end_date"
                    @input="
                        (event) => {
                            $emit('inputChange', { inputName: 'end_date', value: event.target.value })
                        }
                    "
                    :min="currentFilters.start_date"
                    type="date"
                    class="date-input end"
                />
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    components: { Tags },
    props: {
        currentFilters: { type: Object },
        activeFilters: { type: Object }
    },

    data() {
        return {
            temporaryAvailable: JSON.parse(localStorage.getItem('config')).tplTemporary
        }
    },

    computed: {
        optionsLocations() {
            return this.$store.getters['operationalTimers/getSupervisionFilterLocations']
        },
        optionsGroups() {
            return this.$store.getters['operationalTimers/getSupervisionFilterGroups']
        },
        optionsSites() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                {
                    value: 'groups',
                    name: i18n.t('user.filters.territories')
                }
            ]
        },

        optionsTemplates() {
            return this.$store.getters['operationalTimers/getSupervisionFilterTemplates']
        },

        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        }
    },

    methods: {
        filterAutocomplete(item, queryText, itemText) {
            const hasValue = (val) => (val != null ? val : '')

            let text = _.deburr(hasValue(itemText).toString().toLowerCase())
            let query = _.deburr(hasValue(queryText).toString().toLowerCase())

            return text.indexOf(query) > -1
        }
    },

    mounted() {}
}
</script>

<style lang="scss" scoped>
.filter-items {
    .filter {
        .filter {
            .options {
                align-items: center;
            }
        }
    }
}
</style>
